import { navigate } from 'gatsby-link';
import React from 'react';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import { Column, Container, Row } from '../../components/Grid';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import Section from '../../components/Section';

const CareersPage = () => (
  <Layout title="Careers">
    <Hero
      className="hero--careers"
      iconFile="img-coop-careers-illustration.png"
      banner={true}
      heroBanner="Cooperative Careers"
    >
      <h2>Cultivating Career Connections</h2>
      <p>
        Agricultural cooperatives bring together a wide range of careers and
        related industries to help feed the world. Go inside the day-to-day
        lives of real-world professionals working behind-the-scenes for and with
        co-ops, and show students the diverse skills that can be harnessed to
        find success in agriculture and beyond.
      </p>
    </Hero>
    <Section className="pt-0">
      <Container>
        <Row>
          <Column large={6}>
            <p>
              This series introduces students to a wide variety of in-demand
              career paths that are essential to maintaining a successful
              agricultural co-op.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="cooperative-cards-section pt-0">
      <Container>
        <Row className="pb-2">
          <Column medium={4}>
            <Badge text="NEW!" alignTopRight />
            <Image
              className="career__image"
              filename={'img-career-ahmad-clofer.png'}
              onClick={() =>
                navigate('/career-profiles/strategic-sourcing-specialist')
              }
            />
            <h4>Ahmad Clofer</h4>
            <p>
              <em>Strategic Sourcing Specialist </em>
            </p>
            <p>
              How do co-ops get the equipment they need to do their job? Ahmad
              is an important part of the equation and leverages the power of
              the cooperative system to make sure cooperative employees have the
              tractors, excavators, and large vehicles they need.
            </p>
            <Button to="/career-profiles/strategic-sourcing-specialist">
              Learn More
            </Button>
          </Column>
          <Column medium={4}>
            <Badge text="NEW!" alignTopRight />
            <Image
              className="career__image"
              filename={'img-career-dan-warner.png'}
              onClick={() => navigate('/career-profiles/master-electrician/')}
            />
            <h4>Dan Warner</h4>
            <p>
              <em>Master Electrician</em>
            </p>
            <p>
              Meet a Master Electrician and learn how Electricians power the
              production plants critical to the global food supply chain.
            </p>
            <Button to="/career-profiles/master-electrician/">
              Learn More
            </Button>
          </Column>
          <Column medium={4}>
            <Badge text="NEW!" alignTopRight />
            <Image
              className="career__image"
              filename={'img-career-carl-hopp.png'}
              onClick={() =>
                navigate('/career-profiles/flour-production-manager/')
              }
            />
            <h4>Carl Hopp</h4>
            <p>
              <em>Flour Production Manager</em>
            </p>
            <p>
              How are soybeans grown at a farm transformed into the soybean oil
              and flour used in everyday consumer products? Join Carl as he
              takes you behind the scenes of a Flour Mill to discover this
              process.
            </p>
            <Button to="/career-profiles/flour-production-manager/">
              Learn More
            </Button>
          </Column>
        </Row>
        <Row className="pb-2">
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-michaela.png'}
              onClick={() => navigate('/career-profiles/truck-driver/')}
            />
            <h4>Michaela Wendlandt</h4>
            <p>
              <em>Truck Driver</em>
            </p>
            <p>
              Find out how Michaela came full circle working for a co-op after
              having grown up on a farm, using her mechanical-mindedness and
              love for new challenges.
            </p>
            <Button to="/career-profiles/truck-driver/">Learn More</Button>
          </Column>
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-barbara.png'}
              onClick={() => navigate('/career-profiles/nutrition/')}
            />
            <h4>Barbara Carruthers</h4>
            <p>
              <em>Animal Nutritional Consultant</em>
            </p>
            <p>
              Did you know farm animals have nutritionists? Discover the
              combination of science and loving care that Barbara puts into
              maintaining healthy diets.
            </p>
            <Button to="/career-profiles/nutrition/">Learn More</Button>
          </Column>
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-evan.png'}
              onClick={() => navigate('/career-profiles/business-analyst/')}
            />
            <h4>Evan Sieling</h4>
            <p>
              <em>Senior Business Analyst, IT</em>
            </p>
            <p>
              Learn about the cutting-edge field of Precision Agriculture from
              Evan, who helps farmers translate the insights of software tools
              to make data-driven farming decisions.
            </p>
            <Button to="/career-profiles/business-analyst/">Learn More</Button>
          </Column>
        </Row>
        <Row className="pb-2">
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-michelle-adams.jpg'}
              onClick={() =>
                navigate('/career-profiles/logistics-superintendent/')
              }
            />
            <h4>Michelle Adams</h4>
            <p>
              <em>Logistics Superintendent</em>
            </p>
            <p>
              Go inside the many roles involved in managing logistics in an
              export grain terminal, from maintaining schedules for shipments to
              managing transportation to organizing materials and products.
            </p>
            <Button to="/career-profiles/logistics-superintendent/">
              Learn More
            </Button>
          </Column>
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-kris-stone.jpg'}
              onClick={() =>
                navigate('/career-profiles/reliability-maintenance-leader/')
              }
            />
            <h4>Kris Stone</h4>
            <p>
              <em>Reliability & Maintenance Leader</em>
            </p>
            <p>
              Explore how preventive and predictive maintenance help ensure that
              terminal operations will run smoothly and that schedules and
              deadlines will be met.
            </p>
            <Button to="/career-profiles/reliability-maintenance-leader/">
              Learn More
            </Button>
          </Column>
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-ryan-boese.jpg'}
              onClick={() => navigate('/career-profiles/grain-trader/')}
            />
            <h4>Ryan Boese</h4>
            <p>
              <em>Grain Trader</em>
            </p>
            <p>
              Discover how the complex global food supply chain intersects with
              the world of person-to-person business transactions by exploring
              the role of a Grains Trader.
            </p>
            <Button to="/career-profiles/grain-trader/">Learn More</Button>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="cooperative-activities-section">
      <Container>
        <p className="immersive-activity__header">Ready-to-Use Activities</p>
        <Row>
          <Column style={{ boxShadow: '0px 6px 6px 0px #00000040' }} size={6}>
            <Image filename="img-career-RTU-agcooplogistics.png" />
            <div className="immersive-activity__text-container">
              <p className="immersive-activity__title">
                Agriculture Cooperative Logistics: Student Investigation
              </p>
              <p className="immersive-activity__grades">Grades 9–12</p>
              <p className="immersive-activity__description">
                Learn how advanced technologies and efficient grain processing
                are integral to modern farming and supply chain management.
              </p>
              <Button
                target="_blank"
                to="https://app.discoveryeducation.com/learn/player/87122603-c2aa-48d3-b257-0bd308f3c2e2"
              >
                Learn More <Icon name="externallink" marginLeft />{' '}
              </Button>
            </div>
          </Column>
          <Column style={{ boxShadow: '0px 6px 6px 0px #00000040' }} size={6}>
            <Image filename="img-career-RTU-AgProcessingCareers.png" />
            <div className="immersive-activity__text-container">
              <p className="immersive-activity__title">
                Agriculture Processing Careers: Student Investigation
              </p>
              <p className="immersive-activity__grades">Grades 9–12</p>
              <p className="immersive-activity__description">
                Learn how agricultural products get from harvest to final
                product, and develop an understanding of how different careers
                contribute to the overall success of the processing industry and
                its impact on our supply chain.
              </p>
              <Button
                target="_blank"
                to="https://app.discoveryeducation.com/learn/player/8a431159-146c-4a07-b157-622e7e924cdc"
              >
                Learn More <Icon name="externallink" marginLeft />{' '}
              </Button>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default CareersPage;
